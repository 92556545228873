<template>
  <div class="forget-password">
    <el-container>
      <el-header class="main-header">
        <portal-page-header />
      </el-header>
      <el-main>
        <div class="main-content flex-column">
          <div class="forget-text">找回密码</div>

          <div v-if="step == 1">
            <el-form class="zwx-form edit-form" style="margin-top:48px" key="mainForm1" ref="mainForm1" :model="mainForm1" :rules="rules" @submit.native.prevent>
              <div>
                <el-form-item label-width="138px" label="手机号码：" prop="mobileTel">
                  <el-input style="width:416px !important" v-model="mainForm1.mobileTel" placeholder="请输入" maxlength="11" clearable />
                </el-form-item>
              </div>
              <div>
                <el-form-item prop="code" label="验证码：" label-width="138px">
                  <el-input placeholder="请输入验证码" class="code-input" v-model="mainForm1.code" @keyup.enter.native="handler('msForm')" @change="double = 'error' ? (double = true) : ''" style="width:416px">
                    <zwx-verify-code slot="append" ref="verify" />
                  </el-input>
                </el-form-item>
              </div>
              <div>
                <el-form-item label-width="138px" label="短信验证码：" prop="verifyCode">
                  <el-input style="width:416px " v-model="mainForm1.verifyCode" clearable placeholder="请输入短信验证码">
                    <template slot="append">
                      <div class="msg-button" @click="send" :style="showtime !== null ? 'pointer-events: none' : ''">
                        <span v-if="showtime === null">
                          发送
                        </span>
                        <span v-else style="color: #0a4dea!important">
                          {{ showtime }}
                        </span>
                      </div>
                    </template>
                  </el-input>
                </el-form-item>
              </div>
            </el-form>
            <div class="tips">
              <i class="el-icon-warning-outline"></i>
              <span>监管用户若无法找回密码，请联系客服！</span>
            </div>
          </div>
          <div v-if="step == 2">
            <el-form class="zwx-form edit-form" style="margin-top:48px" key="mainForm2" ref="mainForm2" :model="mainForm2" :rules="rules" @submit.native.prevent>
              <div>
                <div>
                  <el-form-item label-width="138px" label="您的登录账号：">
                    {{ userNo }}
                  </el-form-item>
                </div>
                <el-form-item label-width="138px" label="登录密码：" prop="password">
                  <el-input style="width:416px !important" v-model="mainForm2.password" :type="newView ? 'password' : null" placeholder="请输入" maxlength="16">
                    <i slot="suffix" :class="newView ? 'el-input__icon  outline chis-icon-outline-eye' : 'el-input__icon outline chis-icon-outline-eye-invisible'" @click="passSeeNew()"></i>
                  </el-input>
                  <div class="password-level unable-selected" v-show="mainForm2.password !== ''" :class="mainForm2.passwordLevel === 3 ? 'higher' : mainForm2.passwordLevel === 2 ? 'middle' : 'lower'">
                    <div class="password-level-box">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="password-level-text">安全等级：</div>
                    <div class="password-level-code">
                      {{ mainForm2.passwordLevel === 3 ? '高' : mainForm2.passwordLevel === 2 ? '中' : '低' }}
                    </div>
                  </div>
                </el-form-item>
              </div>
              <div>
                <el-form-item label-width="138px" label="确认密码：" prop="confirmPassword">
                  <el-input style="width:416px !important" v-model="mainForm2.confirmPassword" placeholder="请输入" maxlength="20" :type="confirmView ? 'password' : null">
                    <i slot="suffix" :class="confirmView ? 'el-input__icon  outline chis-icon-outline-eye' : 'el-input__icon outline chis-icon-outline-eye-invisible'" @click="passSeeConfirm()"></i>
                  </el-input>
                  <div class="confirmPassword-pass" v-show="$zwxBase.verifyIsNotBlank(mainForm2.password) && $zwxBase.verifyIsNotBlank(mainForm2.confirmPassword) && mainForm2.password == mainForm2.confirmPassword" :class="$zwxBase.verifyIsNotBlank(mainForm2.password) && $zwxBase.verifyIsNotBlank(mainForm2.confirmPassword) && mainForm2.password == mainForm2.confirmPassword ? 'filled chis-icon-filled-completed' : ''" style="color:#10C77B"></div>
                </el-form-item>
              </div>
            </el-form>
            <div class="tips">
              <i class="el-icon-warning-outline"></i>
              <span>监管用户若无法找回密码，请联系客服！</span>
            </div>
          </div>
          <div v-if="step === 3" class="flex-column">
            <div class="register-success"></div>
            <div class="register-success-title">密码修改成功</div>
            <div style="margin-top:38px"><el-button class="zwx-button next-success" type="primary" @click="toLogin">去登录</el-button></div>
          </div>

          <div v-if="step == 1" class="forget-btn">
            <el-button style="background: #5574DF;" type="primary" @click="nextStep">下一步</el-button>
          </div>
          <div v-if="step == 2" class="forget-btn">
            <el-button class="prev-button" @click="lastStep">上一步</el-button>
            <el-button class="" style="background: #5574DF;" type="primary" @click="submit">确认</el-button>
          </div>
        </div>
      </el-main>
    </el-container>
    <div class="bottom">
      <div class="bottom-text">Copyright © {{ domainNumber }} | {{ technicalSupport }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppealEdit',
  components: {},
  props: {},
  data() {
    return {
      api: this.$store.state.api,
      domainNumber: this.$store.state.domainNumber,
      technicalSupport: this.$store.state.technicalSupport,
      step: 1,
      loading: null,
      // 计时器,注意需要进行销毁
      timeCounter: null,
      // null 则显示按钮 秒数则显示读秒
      showtime: null,
      // 短信验证码 替代用户uuid
      uuid: '',
      idCodeSuccessShow: false,
      mainForm1: {
        mobileTel: '',
        //验证码
        verifyCode: '',
        //图片验证码
        code: '',
      },
      userNo: '',
      mainForm2: {
        password: '',
        confirmPassword: '',
        passwordLevel: 0,
      },
      newView: true,
      confirmView: true,
      rules: {
        mobileTel: [
          { required: true, message: '请输入手机号码', trigger: ['change', 'blur'] },
          { validator: this.validateMobile, trigger: ['blur'] },
        ],
        verifyCode: [{ required: true, message: '请输入短信验证码', trigger: ['change', 'blur'] }],
        code: [{ required: true, message: '请输入验证码', trigger: ['change', 'blur'] }],
        password: [
          { required: true, message: '请输入密码', trigger: ['change', 'blur'] },
          { validator: this.validatePasswordLevel, trigger: ['change', 'blur'] },
        ],
        confirmPassword: [
          { required: true, message: '请输入密码', trigger: ['change', 'blur'] },
          { required: true, validator: this.confirmPasswordRules, trigger: ['change', 'blur'] },
        ],
      },
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    toLogin() {
      this.$router.push({ name: 'Login' })
    },
    // 点击下一步
    nextStep() {
      this.$refs['mainForm1'].validate(valid => {
        if (valid) {
          this.loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.3)',
          })
          let data = this.mainForm1
          data.verifyUid = this.$refs.verify.uid
          data.smsVerifyCode = this.mainForm1.verifyCode
          this.$system.get(
            this.api + '/zky/employer/verifyRetrievePasswordNext-0',
            data,
            false,
            true,
            data => {
              if (data.type === '00') {
                this.loading.close()
                this.loading = null
                this.step = 2
                this.userNo = data.userNo
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
                this.loading.close()
                this.loading = null
              } else {
                this.$notify.error({ title: '错误', message: data.mess })
                this.loading.close()
                this.loading = null
              }
            },
            this.error
          )
        }
      })
    },
    //发送验证码
    // 倒计时显示处理
    countDownText(s) {
      this.showtime = `${s}s后重新获取`
    },
    countDown(times) {
      const self = this
      // 时间间隔 1秒
      const interval = 1000
      let count = 0
      self.timeCounter = setTimeout(countDownStart, interval)

      function countDownStart() {
        if (self.timeCounter == null) {
          return false
        }
        count++
        self.countDownText(times - count + 1)
        if (count > times) {
          clearTimeout(self.timeCounter)
          self.showtime = null
        } else {
          self.timeCounter = setTimeout(countDownStart, interval)
        }
      }
    },
    //点击发送
    send() {
      if (!this.mainForm1.mobileTel) {
        this.$refs['mainForm1'].validateField('mobileTel')
        return
      }
      if (!this.mainForm1.code) {
        this.$refs['mainForm1'].validateField('code')
        return
      }
      // this.$refs['mainForm1'].validateField('mobileTel', (err) => {
      //   if (!err) {
      this.loading = this.$loading({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.3)',
      })
      let data = {
        verifyUid: this.$refs.verify.uid,
        verifyCode: this.mainForm1.code,
        mobileTel: this.mainForm1.mobileTel,
        messageType: 2,
      }

      // 发送短信验证码
      this.$system.get(
        this.api + '/zky/employer/verifyRetrievePasswordBefore-0',
        data,
        false,
        true,
        data => {
          if (data.type === '00') {
            this.countDown(60)
            this.$system.notify('成功', data.mess, 'success')
          } else if (data.type === '99') {
            this.$refs.verify.imgCodeReload()
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$refs.verify.imgCodeReload()
            this.$system.notify('错误', data.mess, 'error')
          }
          this.loading.close()
          this.loading = null
        },
        this.error
      )
    },
    passSeeNew() {
      this.newView = !this.newView
    },
    passSeeConfirm() {
      this.confirmView = !this.confirmView
    },
    lastStep() {
      this.step--
    },
    submit() {
      this.$refs['mainForm2'].validate(valid => {
        if (valid) {
          this.loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.3)',
          })
          let data = {
            mobileTel: this.mainForm1.mobileTel,
            passWord: this.mainForm2.password,
            confirmPassWord: this.mainForm2.confirmPassword,
          }
          this.$system.post(
            this.api + '/zky/employer/verifyRetrievePassword-0',
            data,
            false,
            true,
            data => {
              if (data.type === '00') {
                this.loading.close()
                this.loading = null
                this.step = 3
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
                this.loading.close()
                this.loading = null
              } else {
                this.$notify.error({ title: '错误', message: data.mess })
                this.loading.close()
                this.loading = null
              }
            },
            this.error
          )
        }
      })
    },
    /**
     * 手机号校验
     */
    validateMobile(rule, value, callback) {
      var patt = /^[1][3-9][\d]{9}/
      if (!patt.test(value)) {
        callback(new Error('请输入正确手机号'))
      }
      callback()
    },
    /**
     * 密码强度校验
     */
    validatePasswordLevel(rule, value, callback) {
      if (value.length < 8) {
        this.mainForm2.passwordLevel = 1
        callback(new Error('密码应为8-16位，必须包含大写字母、小写字母和数字'))
      }
      let lev = 0
      //如果密码中存在数字，强度加一
      if (value.match(/\d+/g)) {
        lev++
      }
      //如果密码中存在小写字母，强度加一
      if (value.match(/[a-z]+/g) && value.match(/[A-Z]+/g)) {
        lev++
      }
      //如果密码中存在特殊字符，强度加一
      if (value.match(/[^a-zA-Z0-9]+/g)) {
        lev++
      }
      this.mainForm2.passwordLevel = lev
      if (lev < 2) {
        callback(new Error('密码应为8-16位，必须包含大写字母、小写字母和数字'))
      }
      callback()
    },
    /**
     * 密码校验
     */
    confirmPasswordRules(rule, value, callback) {
      if (!rule.required && (value === undefined || value === null || value.length === 0)) return callback()
      if (this.mainForm2.password !== this.mainForm2.confirmPassword) return callback(new Error('两次输入的密码不一致'))
      return callback()
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.loading.close()
      this.loading = null
    },
  },
}
</script>

<style scoped lang="less">
.flex-row {
  display: flex;
  align-items: center;
}

.flex-row-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-row-align-end {
  display: flex;
  align-items: flex-end;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tips {
  margin-top: 15px;
  padding-left: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    margin-right: 3px;
    color: orange;
  }
  span {
    color: orange;
  }
}
.forget-password {
  height: 100%;
  .main-header {
    height: 85px !important;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 4px rgba(222, 227, 236, 0.8);
    z-index: 2;
  }

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 16px;
    color: #999999;
    .bottom-text {
      position: fixed;
      bottom: 10px;
    }
  }
  .main-content {
    margin-top: 48px;
    /deep/.el-icon-circle-close {
      line-height: 46px !important;
    }
    /deep/.zwx-form .el-form-item__content {
      line-height: 46px !important;
    }
    .select-component {
      /deep/ .zwx-input {
        width: 416px !important;
        height: 46px !important;
        line-height: 46px !important;
        cursor: pointer;
      }

      /deep/ .el-input__suffix {
        right: 6px !important;
      }

      /deep/ .el-input__inner {
        border: 1px solid rgba(215, 218, 224, 1) !important;
        border-radius: 4px !important;
        width: 416px;
        padding-left: 15px !important;
      }

      /deep/ .zwx-input .el-input__suffix .el-input__suffix-inner .el-input__icon {
        margin-top: 0;
        line-height: 46px !important;
      }

      /deep/ .zwx-input-up {
        margin-left: -32px !important;
      }
    }

    .msg-button {
      cursor: pointer;
      width: 100px;
      height: 100%;
      text-align: center;
    }
    .code-input {
      /deep/ .el-input__inner {
        height: 52px !important;
      }
      /deep/ .el-input-group__append {
        padding: 0 !important;
      }
    }
    /deep/ .el-form-item__label {
      line-height: 46px !important;
    }
    /deep/.el-input__inner {
      height: 46px !important;
      line-height: 46px !important;
    }
    .forget-text {
      font-size: 24px;
      font-family: 'Harmony Medium';
      font-weight: 500;
      color: #3d3f44;
      line-height: 24px;
    }
    .forget-btn {
      width: 534px;
      margin-top: 32px;
      text-align: right;

      /deep/.el-button {
        width: 132px !important;
      }
      .prev-button {
        height: 36px;
        line-height: 36px !important;
        padding: 0 42px !important;
        color: #5574df;
        font-weight: unset;
        border: 1px solid #5574df;
        // border: 1px solid #D7DAE0;
        // color: #3d3f44;
      }
    }
    .password-level {
      position: absolute;
      top: 12px;
      right: -225px;
      z-index: 2;
      height: 24px;
      display: flex;
      justify-content: center;

      &-box {
        display: inline-flex;
        justify-content: space-between;
        width: 110px;

        div {
          margin: auto;
          width: 34px;
          height: 8px;
          background: rgba(242, 245, 252, 1);
        }
      }

      &-text {
        line-height: 24px;
        padding-left: 10px;
        color: rgba(153, 153, 153, 1);
        font-size: 14px;
      }

      &-code {
        line-height: 24px;
        font-size: 14px;
        color: #f73636;
      }
    }
    .confirmPassword-pass {
      position: absolute;
      top: 0;
      right: -30px;
      z-index: 2;
      height: 24px;
      display: flex;
      justify-content: center;
    }

    .password-level.lower {
      .password-level-box {
        div:first-child {
          background: #f73636;
        }
      }
    }

    .password-level.middle {
      .password-level-box {
        div {
          background: #f78a36;
        }

        div:last-of-type {
          background: #f2f5fc;
        }
      }

      .password-level-code {
        color: #f78a36;
      }
    }

    .password-level.higher {
      .password-level-box {
        div {
          background: #10c77b;
        }
      }

      .password-level-code {
        color: #10c77b;
      }
    }
    .register-success {
      margin-top: 100px;
      width: 228px;
      height: 188px;
      background-image: url('../../../assets/images/system/img_register_success.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .register-success-title {
      margin-top: 20px;
      width: 132px;
      height: 26px;
      font-size: 22px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #3d3f44;
      line-height: 26px;
    }
    .next-success {
      width: 188px;
      height: 48px;
      line-height: 48px !important;
      padding: 0 42px !important;
      color: #ffffff;
      font-weight: unset;
      background: #5574df;
      border-radius: 4px;
      border: none;
    }
  }
}
</style>
